<template>
  <div>
    <el-dialog v-model="view" width="1000px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="steps">
        <el-steps :active="form.Steps">
          <el-step title="项目发起申请" description="请填写项目发起的资料"></el-step>
          <el-step title="审核" description="审核。"></el-step>
          <el-step title="确认发起签字" description="发起签字"></el-step>
          <el-step title="等待签字完毕" description="等待协议中所有成员签字完毕"></el-step>
          <el-step title="完成" description="完成签字，项目成功发起"></el-step>
        </el-steps>
      </div>
      <div class="form">
        <el-form label-width="100px">
          <el-form-item label="项目标题" :required="true">
            <el-input v-model="form.Title" size="small"></el-input>
          </el-form-item>
          <el-form-item label="标语" :required="true">
            <el-input v-model="form.ActivityName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="委员会数量">
            <el-input-number v-model="form.CommitteeNumber" label="请输入未使用额度" controls-position="right" :min="1"
                             :max="1000000"></el-input-number>
          </el-form-item>
          <el-form-item label="共同发起人" :required="true">
          <span v-for="(item, index) in form.Personnel" :key="index">
                  {{ item.Name }}-{{ item.Mobile }}<i class="el-icon-delete" @click="deleteItem(item, index)"></i>
          </span>
          </el-form-item>
          <el-form-item>
            <el-button @click="OpenUser" size="small">添加共同发起人</el-button>
          </el-form-item>
          <el-form-item label="贡献值额度" :required="true">
            <el-input-number v-model="form.ToNumber" :min="1" :max="10000000000" label="初始额度"
                             size="small"></el-input-number>
          </el-form-item>
          <el-form-item label="LOGO" :required="true" class="changeImg">
            <img v-if="form.ImageUrl" :src="form.ImageUrl" class="avatar">
            <el-upload
                class="upload-block"
                accept="image/png,image/jpg,image/jpeg"
                :action='actionUrl'
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <el-button type="default" size="small">上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="项目目标及简介" :required="true">
            <el-input
                size="small"
                type="textarea"
                :rows="5"
                placeholder="项目目标及简介"
                v-model="form.Brief">
            </el-input>
          </el-form-item>
          <el-form-item label="补充协议">
            <el-input
                size="small"
                type="textarea"
                :rows="5"
                placeholder="补充协议"
                v-model="form.EnclosureText">
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">申请</el-button>
                </span>
      </template>
    </el-dialog>
    <ChoiceUser ref="User"></ChoiceUser>
  </div>

</template>

<script>
import {Actions,ApplyProject} from "../../../api/ProjectAdmin/NumberAdmin";
import ChoiceUser from '../../../components/ChoiceUser'

export default {
  data() {
    return {
      form: {
        Steps: 0,
        Title: '',
        ActivityName: '',
        Personnel: [],
        ToNumber: 0,
        CommitteeNumber: 0,
        Brief: '',
        EnclosureText: '',
        ImageUrl: ''
      },
      actionUrl: '',
      view: false
    }
  },
  mounted() {
    this.actionUrl = Actions()
  },
  methods: {
    closeInput(){
      this.form.Personnel=[]
      this.form.ImageUrl=''
      this.form.Title=''
      this.form.ActivityName=''
      this.form.ToNumber=0
      this.form.CommitteeNumber=0
      this.form.Brief=''
      this.form.EnclosureText=''
    },
    OpenUser() {
      this.$refs.User.handlAdd()
    },
    // 选中名字
    handlSelected(row) {
      this.form.Personnel.push({
        User_id: row.User_id,
        Name: row.Name,
        Mobile: row.Mobile,
      })
    },
    deleteItem(item, index) {
      this.$confirm("是否要删除该成员吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.form.Personnel.splice(index, 1)
      })
    },
    saveEdit() {
      let data=JSON.parse(JSON.stringify(this.form))
      let UserInfo = this.$store.state.UserInfo
      let Admin_id=UserInfo.Admin_id
      this.openLoading()
      ApplyProject({...data,Admin_id}).then(res=>{
        this.closeLoading()
        if (res.code == 0) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.tableData=res.sum
          this.closeInput()
          this.$parent.getData()
          this.view = false


        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    open() {
      this.closeInput()
      this.view = true
    },
    handleAvatarSuccess(res) {
      this.form.ImageUrl = res.imgfile;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!');
      }
      return isJPG && isLt2M;
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '提交中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },

    closeLoading() {
      this.loading.close();
    },
  },
  components: {
    ChoiceUser
  }
}
</script>

<style scoped lang="scss">
.steps {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 45px;
}

.form {
  height: 400px;
  overflow-y: auto;
}

.avatar {
  width: 90px;
  height: 90px;
  display: block;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}

.changeImg .avatar-uploader .el-upload {
  border-radius: 50%;
}

.el-icon-delete {
  margin-left: 10px;
  cursor: pointer;
}
</style>