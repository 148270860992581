<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 项目查看与发起
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container" v-loading="loading">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handladd"  size="small">发起项目</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          size="small"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column label="logo">
          <template #default="scope">
            <el-avatar shape="square" :size="50" :src="scope.row.Logo"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="Title" label="社区项目" width="190"></el-table-column>
        <el-table-column prop="ActivityName" label="标语" width="250" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="CommitteeNumber" label="委员会数量"></el-table-column>
        <el-table-column prop="ToNumber" label="贡献值额度"></el-table-column>
        <el-table-column prop="NewTime" label="成立时间"></el-table-column>
        <el-table-column prop="InShow" label="是否显示">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.InShow">显示</el-tag>
            <el-tag type="danger" v-else>不显示</el-tag>
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template #default="scope">
            <el-button type="text" class="green" @click="openNumberAdd(scope.row)">添加总额度</el-button>
            <el-button type="text" class="red" @click="openNumberReduce(scope.row)">减少总额度</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <ApplyProject ref="ApplyProject"></ApplyProject>
    <AddNumber ref="AddNumber"></AddNumber>
  </div>
</template>

<script>

import {fetchData,  AddData, UpData} from "../../api/ProjectAdmin/NumberAdmin";
import ApplyProject from './com/ApplyProject'
import AddNumber from './com/AddNumber'

export default {
  data() {
    return {
      loading:false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal:0
      },
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    openNumberReduce(data){
      this.$refs.AddNumber.open(data,'减少')
    },
    openNumberAdd(data){
      this.$refs.AddNumber.open(data,'添加')
    },
    handladd() {
      this.$refs.ApplyProject.open()
    },
    getData() {
      this.loading=true
      let skip = this.query.pageIndex
      fetchData({skip}).then(res => {
        this.loading=false
        let bindata = res.sum;
        this.pageTotal = res.number
        this.tableData = bindata;
      });
    },
    // 保存编辑
    saveEdit() {
      let start = this.edit.start
      let form = {...this.form}

      if (start == '添加') {
        if (form.Brief && form.ComitemNumber >= 1 && form.Explain && form.ImageUrl && form.NewNumber >= 1 && form.Personnel.length >= 1 && form.Title) {
          if (form.Enclosure) {
            if (!form.EnclosureText) {
              this.$alert('缺少必填', '系统', {
                confirmButtonText: '确定',
              });
              return
            }
          }
          let UserInfo = this.$store.state.UserInfo
          AddData({...form, UserInfo}).then(res => {
            if (res.code == 0) {
              this.getData();
              this.edit.user = false;
              this.form = {}
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          this.$alert('缺少必填', '系统', {
            confirmButtonText: '确定',
          });
        }
      } else {
        if (form.Explain) {
          UpData(form).then(res => {
            if (res.code == 0) {
              this.getData();
              this.edit.user = false;
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          this.$message.error('缺少必填项');
        }
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    }
  },
  components: {
    ApplyProject,
    AddNumber
  }
};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green{
 color: #00a854;
}

.red{
  color: red;
}

</style>
