<template>
  <el-dialog v-model="view" width="1000px" :close-on-click-modal="false" :close-on-press-escape="false" :title="title">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="bg-purple">未使用额度：<b>{{ data.NoNumber }} QB</b></div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple green">已使用额度：<b>{{ data.SetNumber }} QB</b></div>
      </el-col>
      <el-col :span="8">
        <div class="bg-purple yell">总额度：<b>{{ data.ToNumber }} QB</b></div>
      </el-col>
    </el-row>

    <el-form label-width="80px" class="el-dialog-div">
      <el-form-item label="添加额度">
        <el-input-number v-model="form.Number" :min="1"></el-input-number>
      </el-form-item>
    </el-form>

    <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确定</el-button>
                </span>
    </template>
  </el-dialog>
</template>

<script>
import {FindCommittee} from "../../../api/ProjectAdmin/Type";
import {NumberAdd, NumberReduce} from "../../../api/ProjectAdmin/NumberAdmin";

export default {
  data() {
    return {
      view: false,
      App_id: '',
      title: '',
      data: {
        TolNumber: 0,
        SetNumber: 0,
        NoNumber: 0
      },
      form: {
        Number: 1
      }
    }
  },
  methods: {
    open(str, style) {
      let data = JSON.parse(JSON.stringify(str))
      let App_id = data.App_id
      this.style = style
      this.title = style + '总额度'
      this.App_id = App_id
      FindCommittee({App_id}).then(res => {
        let Tol = res.Tol
        this.data = {...Tol}
      })
      this.view = true
    },
    saveEdit() {
      let Number = this.form.Number
      let App_id=this.App_id
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      if ( this.style=='添加'){
        NumberAdd({Number,App_id,Admin_id}).then(res=>{
          if (res.code==0){
            this.$message.success(res.message)
            this.close()
          } else {
            this.$message.error(res.message)
          }
        })
      }else {
        NumberReduce({Number,App_id,Admin_id}).then(res=>{
          if (res.code==0){
            this.$message.success(res.message)
            this.close()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    close(){
      this.view=false
      this.$parent.getData()
      this.form.Number=1
    }
  }
}
</script>

<style scoped lang="scss">
.el-dialog-div {
  margin-top: 20px;
}

.bg-purple {
  background-color: #e5e6e1;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-indent: 20px;
  border-radius: 5px;
}

.green {
  background-color: #00a854;
  color: #ffffff;
}

.yell {
  background-color: #e6790c;
  color: #ffffff;
}
</style>